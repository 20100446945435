<template>
  <div class="demandDetail">
    <div class="tabar">
      <span>您所在的位置：&nbsp;&nbsp;</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/portal/demandHall' }"
          >需求大厅</el-breadcrumb-item
        >
        <el-breadcrumb-item>需求详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="Content">
      <div class="picContent">
        <div class="messageBox">
          <div class="picBox">
            <img :src="picList[picIndex]" alt="" />
          </div>
          <div class="messageContent">
            <div class="m-title">{{ dateObj.title }}</div>
            <div class="m-text">
              <div class="text1">品种名称</div>
              <span>{{ dateObj.serviceVarietyName }}</span>
            </div>
            <div class="m-text">
              <div class="text1">需求类型</div>
              <span>{{ dateObj.serviceTypeName }}</span>
            </div>
            <div class="m-text">
              <div class="text1">需求数量</div>
              <span>{{ dateObj.quantity }}</span>
            </div>
            <div class="m-text">
              <div class="text1">服务价格</div>
              <span
                v-if="dateObj.budgetType !== 1"
                style="font-size: 26px; color: #17a763"
                >{{ dateObj.budget }}</span
              >
              <span v-if="dateObj.budgetType !== 1">{{
                dateObj.unitName ? "/" + dateObj.unitName : ""
              }}</span>
              <span
                style="font-size: 26px; color: #17a763"
                v-if="dateObj.budgetType === 1"
                >面议</span
              >
            </div>
            <div class="m-text">
              <div class="text1">起止日期</div>
              <span>{{
                (dateObj.startTime || "") + " 至 " + (dateObj.endTime || "")
              }}</span>
            </div>
            <div class="m-text">
              <div class="text1">需求地址</div>
              <span>{{ optArea(dateObj) || "暂无" }}</span>
            </div>
            <div class="m-text">
              <div class="text1">距离结束</div>
              <span>{{ dateObj.finishDay > 0 ? dateObj.finishDay : "已截止" }}</span>
            </div>
            <div class="m-text">
              <div class="text1">发布人</div>
              <span>{{ strName }}</span>
            </div>
            <div class="m-text">
              <div class="text1">浏览次数</div>
              <span>{{ dateObj.viewTotal }}</span>
            </div>
          </div>
        </div>
        <div class="picList">
          <div class="picLittContent" v-if="picList.length > 0">
            <i class="el-icon-arrow-left" @click="reduceIndex"></i>
            <img
              @click="handleImg(index)"
              v-for="(item, index) in picList"
              :key="index"
              :src="item"
            />
            <i class="el-icon-arrow-right" @click="addIndex"></i>
          </div>
          <div class="tokenContent">
            <div class="btnContent" @click="contactMe">
              <img src="../../assets/image/phone.png" alt="" />
              <span>立即联系</span>
            </div>
          </div>
        </div>
        <div class="searvContent">
          <div class="xq-title">需求详情</div>
          <div class="xq-content">
            <div class="xq-box">
              <div class="mx-box">
                <img src="../../assets/image/file.png" alt="" />
                <span>描述</span>
              </div>
              <div v-html="optDetail()" style="width: 900px; word-break: break-all"></div>
            </div>
          </div>
          <div class="sear-title">周边推荐</div>
          <div class="servBox">
            <div class="tableContent">
              <el-table :data="dataList" style="width: 100%">
                <el-table-column prop="date" label="需求内容" width="180">
                  <template slot-scope="scope">
                    <div class="tb-imgBox">
                      <img :src="optImage(scope.row)" alt="" />
                      <span>{{ scope.row.serviceTypeName }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="orgName" label="生产主体" width="180">
                </el-table-column>
                <el-table-column width="200" :formatter="optArea" label="需求地址">
                </el-table-column>
                <el-table-column
                  prop="quantity"
                  label="需求数量"
                  align="center"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  width="100"
                  prop="budget"
                  label="需求预算"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.budgetType == 1">面议</span>
                    <span v-else>{{ scope.row.budget }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="服务时间" show-overflow-tooltip align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.startTime + "-" + scope.row.endTime }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="pageContent">
                <el-pagination
                  v-show="total > 0"
                  @current-change="changePage"
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="formDate.pageSize"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightContent">
        <div class="housContent">
          <img src="../../assets/image/people.png" alt="" />
          <div class="text1">
            {{ strName }}
          </div>
          <div class="text2">
            <div class="text3">
              区域：&nbsp;&nbsp;&nbsp;{{ optArea(dateObj) || "暂无" }}
            </div>
          </div>
          <!-- <div class="text2">
            <div class="text3">关注品种：{{ "暂无" }}</div>
          </div> -->
        </div>
        <div class="hotServe">
          <div class="line-box">
            <div class="hs-title">热门服务</div>
          </div>
          <div class="imgBox">
            <div v-for="(item, index) in hotServiceRelease" :key="index" class="img-box">
              <img :src="optImage(item)" alt="" />
              <div
                @click="changeClick(item)"
                style="
                  margin-bottom: 15px;
                  width: 280px;
                  word-break: break-all;
                  cursor: pointer;
                "
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDemandDetailVo } from "@/api/serviechall";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      picList: [],
      dateObj: {},
      picIndex: 0,
      organization: {},
      hotServiceRelease: [],
      dataList: [],
      strName: "",
      total: 0,
      formDate: {
        pageSize: 4,
        pageNum: 1,
      },
    };
  },
  methods: {
    changePage(page) {
      let query = { id: this.$route.query.id, recommendPageNum: page };
      this.getDemandDetailVo(query);
    },
    changeStr(index) {
      this.str = this.array[index];
    },
    addIndex() {
      let length = this.picList.length;
      this.picIndex = (this.picIndex + 1) % length;
    },
    reduceIndex() {
      let length = this.picList.length;
      this.picIndex = this.picIndex == 0 ? length : this.picIndex;
      this.picIndex = (this.picIndex - 1) % length;
    },
    handleImg(item) {
      this.picIndex = item;
    },
    contactMe() {
      if (!getToken()) {
        this.$confirm("您当前还未登录要跳转到登录界面吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/portal/login");
          })
          .catch(() => {});
        return;
      }
      if (this.dateObj.finishDay > 0) {
        this.$confirm(`联系电话： ${this.dateObj.phone}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {})
          .catch(() => {});
      } else {
        this.$message.info("该需求已结束");
      }
    },
    optArea(item) {
      const { province, city, county, town, village } = item;
      return (
        (province || "") + (city || "") + (county || "") + (town || "") + (village || "")
      );
    },
    optNmae(date1, date2) {
      if (date1) {
        this.strName = date1.name || "";
      } else if (date2) {
        this.strName = date2.name || "";
      }
    },
    optDetail() {
      let demandDetail = this.dateObj.demandDetail;
      if (!demandDetail) {
        return;
      }
      if (this.isBase64(demandDetail)) {
        demandDetail = escape(window.atob(demandDetail));
        try {
          demandDetail = decodeURIComponent(demandDetail);
        } catch (error) {}
      }
      return demandDetail;
    },
    isInclude(URL) {
      return URL;
      if (URL.indexOf("%") > -1) {
        return URL.replace(/%/g, "%25");
      } else {
        return URL;
      }
    },
    isBase64(str) {
      str = String(str);
      if (str === "" || str.trim() === "") {
        return false;
      }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
      return true;
    },
    getDemandDetailVo(query) {
      getDemandDetailVo(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          const {
            omsDemand,
            cmsVillageCollective,
            cmsProductionOrganization,
            hotOmsDemandList,
            pageInfo,
          } = data;
          this.dateObj = omsDemand;
          this.optNmae(cmsVillageCollective, cmsProductionOrganization);
          if (hotOmsDemandList) {
            this.hotServiceRelease = hotOmsDemandList.splice(0, 7);
          }
          if (omsDemand.imgUrl) {
            let list = omsDemand.imgUrl.split(",");
            this.picList = list.splice(0, 4);
          }
          if (pageInfo) {
            this.total = pageInfo.total;
            this.dataList = pageInfo.list;
          }
        }
      });
    },
    changeClick(item) {
      let query = { id: item.id, recommendPageNum: 1 };
      this.getDemandDetailVo(query);
    },
    optImage(item) {
      if (item.imgUrl) {
        return item.imgUrl.split(",")[0];
      }
    },
  },
  created() {
    let query = { id: this.$route.query.id, recommendPageNum: 1 };
    this.getDemandDetailVo(query);
  },
};
</script>
<style lang="scss" scoped>
.demandDetail {
  width: 100%;
  .tabar {
    height: 52px;
    width: 1396px;
    margin: 0 auto 20px;
    color: #555;
    font-size: 12px;
    display: flex;
    align-items: center;
    /deep/ .el-breadcrumb {
      font-size: 12px;
      color: #555;
    }
  }
  .Content {
    width: 1396px;
    margin: 0 auto;
    padding-left: 20px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .picContent {
      width: 1046px;
      .messageBox {
        height: 454px;
        display: flex;
        .picBox {
          width: 454px;
          height: 454px;
          border: 1px solid #dddddd;
          display: flex;
          align-items: center;
          img {
            width: 454px;
            height: 300px;
          }
        }
        .messageContent {
          width: 570px;
          margin-left: 20px;
          border-bottom: 1px solid #dddddd;
          .m-title {
            font-size: 20px;
            color: #000;
            font-weight: bold;
            height: 44px;
            border-bottom: 1px solid #dddddd;
          }
          .m-text {
            display: flex;
            align-items: center;
            padding: 10px 0px;
            font-size: 14px;
            color: #333;
            .text1 {
              width: 100px;
            }
          }
        }
      }
      .picList {
        width: 100%;
        display: flex;
        padding-left: 10px;
        font-size: 14px;
        color: #999999;
        align-items: center;
        margin-top: 15px;
        .picLittContent {
          display: flex;
          align-items: center;
          width: 455px;
          overflow: hidden;
        }
        .tokenContent {
          display: flex;
          align-items: center;
          .btnContent {
            height: 54px;
            font-size: 18px;
            color: #17a763;
            border-radius: 10px;
            border: 1px solid #17a763;
            margin-right: 17px;
            display: flex;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;
            cursor: pointer;
            img {
              width: 28px;
              height: 30px;
            }
          }
        }
        img {
          width: 96px;
          height: 96px;
          margin-right: 8px;
        }
      }
      .searvContent {
        margin-top: 60px;
        .xq-title {
          margin-top: 25px;
          height: 60px;
          line-height: 60px;
          padding-left: 33px;
          font-size: 18px;
          color: #333333;
          background: url("../../assets/image/file1.png") no-repeat left center;
        }
        .xq-content {
          border: 1px solid #dddddd;
          padding: 20px;
          .xq-box {
            padding: 20px 0px;
            min-height: 203px;
            border-bottom: 1px dashed #dddddd;
            font-size: 14px;
            color: #333;
            display: flex;
            .mx-box {
              width: 92px;
              display: flex;
              align-items: center;
              flex-direction: column;
              img {
                width: 56px;
                height: 62px;
                margin-bottom: 6px;
              }
            }
          }
        }
        .sear-title {
          width: 190px;
          margin-top: 40px;
          text-align: center;
          border: 1px solid #dddddd;
          border-bottom: none;
          border-radius: 5px;
          background-color: #f8f8f8;
          height: 59px;
          line-height: 59px;
          font-size: 18px;
          color: #333333;
          cursor: pointer;
        }
        .servBox {
          padding: 0 20px;
          border: 1px solid #dddddd;
          .tableContent {
            .tb-imgBox {
              width: 100%;
              display: flex;
              align-items: center;
              word-break: break-all;
              padding: 10px 0;
              img {
                width: 65px;
                height: 65px;
                margin-right: 10px;
              }
            }
          }
          .pageContent {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 10px 0;
            /deep/ .el-pager li:not(.disabled).active {
              background-color: #17a763;
              color: #fff;
            }
          }
          .left-box {
            padding: 20px 0px;
            display: flex;
            text-align: center;
            .ser-pic {
              margin-right: 20px;
            }
            img {
              width: 56px;
              height: 62px;
            }
            .serBoxContent {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              img {
                width: 210px;
                height: 155px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .rightContent {
      width: 280px;
      .housContent {
        padding: 22px;
        background-color: #f9f9f9;
        border: 1px solid #dddddd;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        .text1 {
          margin-bottom: 10px;
          text-align: center;
        }
        .text2 {
          text-align: start;
          margin-top: 4px;
          display: flex;
          .text3 {
            font-size: 14px;
            font-weight: 500;
            color: #333;
            margin-left: 10px;
            margin-bottom: 4px;
            word-break: break-all;
            width: 200px;
            line-height: 20px;
          }
          #text4 {
            line-height: 20px;
            font-weight: bold;
            font-size: 14px;
          }
        }
        color: #333;
        img {
          width: 140px;
          height: 130px;
          margin-bottom: 10px;
        }
        /deep/ .el-button {
          width: 218px;
          height: 46px;
          background-color: #17a763;
          border-radius: 5px;
          text-align: center;
          font-size: 18px;
          color: #fff;
        }
      }
      .hotServe {
        width: 280px;
        .line-box {
          width: 100%;
          border-bottom: 2.5px solid #dddddd;
          height: 34px;
        }
        .hs-title {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          height: 36px;
          line-height: 39px;
          padding-left: 15px;
          width: 80px;
          box-sizing: border-box;
          border-bottom: 3.5px solid #17a763;
        }
        .imgBox {
          width: 100%;
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 100%;
          img {
            width: 213px;
            height: 141px;
          }
          .img-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
